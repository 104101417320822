import OuterGooglePayField from './OuterGooglePayField';
import OuterGooglePayListener from './OuterGooglePayListener';

class OuterGooglePayFieldFactory {
  constructor(config) {
    this.config = config;
    this.listener = null;
  }

  getListener() {
    if (this.listener === null) {
      this.listener = new OuterGooglePayListener();
      this.listener.mount();
    }

    return this.listener;
  }

  getGooglePayErrors(options) {
    const errors = [];

    const numberOfMatchingElements = document.querySelectorAll(
      this.config.fields.googlePay.selector,
    ).length;
    if (numberOfMatchingElements === 0) {
      return [];
    }

    if (numberOfMatchingElements > 1) {
      errors.push(
        `You may only mount 1 Google Pay button. ` +
          `'${this.config.fields.googlePay.selector}' matches ${numberOfMatchingElements} elements.`,
      );
    }

    if (typeof options.country !== 'string') {
      errors.push('country must be provided to Collect.js when using Google Pay.');
    }

    if (typeof options.price !== 'string') {
      errors.push('price must provided to Collect.js as a string when using Google Pay.');
    }

    if (typeof options.currency !== 'string') {
      errors.push('currency must be provided to Collect.js when using Google Pay.');
    }

    if (typeof options.currency === 'string' && !options.currencies.includes(options.currency)) {
      if (options.currencies.length === 0) {
        errors.push('You do not have Google Pay enabled.');
      } else {
        errors.push(`You do not have a Google Pay enabled processor that supports ${
          options.currency
        }.
              Available currencies include: ${options.currencies.join(',')}`);
      }
    }

    return errors;
  }

  create(options) {
    const errors = this.getGooglePayErrors(options);

    if (errors.length > 0) {
      errors.forEach(error => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
      return {};
    }

    return new OuterGooglePayField({
      country: options.country,
      price: options.price,
      currency: options.currency,
      billingAddressRequired: options.billingAddressRequired,
      billingAddressParameters: options.billingAddressParameters,
      shippingAddressRequired: options.shippingAddressRequired,
      shippingAddressParameters: options.shippingAddressParameters,
      buttonType: options.buttonType,
      buttonColor: options.buttonColor,
      buttonLocale: options.buttonLocale,
      totalPriceStatus: options.totalPriceStatus,
      emailRequired: options.emailRequired,
      merchantId: options.merchantId,
      merchantName: options.merchantName,
      cardBrands: options.cardBrands,
      environment: options.environment,
      listener: this.getListener(),
      token: options.token,
      tokenizationKey: options.tokenizationKey,
      cartCorrelationId: options.cartCorrelationId,
    });
  }
}

export default OuterGooglePayFieldFactory;
