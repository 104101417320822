/* eslint-env browser */
export default class SafariFocusFix {
  constructor() {
    this.previousFocus = null;
    this.eventAdded = false;
    this.iframes = {};
    this.touchStart = this.touchStart.bind(this);
  }

  static isAppleDevice() {
    return navigator.userAgent.match(/(iPod|iPhone|iPad)/) != null;
  }

  touchStart(event) {
    if (!SafariFocusFix.canElementReceiveFocus(event.target)) {
      return;
    }
    const ids = Object.entries(this.iframes).reduce((carry, [, element]) => {
      carry.push(element.id);
      return carry;
    }, []);

    if (
      this.previousFocus instanceof HTMLIFrameElement &&
      this.previousFocus.id !== event.target.id
    ) {
      this.previousFocus.contentWindow.postMessage({ action: 'blur' }, '*');
    }

    if (ids.includes(event.target.id)) {
      this.previousFocus = this.iframes[event.target.id.replace('CollectJSInline', '')];
    } else {
      this.previousFocus = null;
    }
  }

  // HACK ALERT:
  // DEV-10772
  // This is to fix the iOS safari issue where iframes each hold their own focus. This prevents the normal focus
  // event from firing for the iframe when another element gains focus, so we have to listen for a focusin event
  // on the body (since focusin does bubble whereas focus doesn't) and send a blur message to the previously
  // focused iframe so it will run its validation.
  registerTouchStartListener() {
    if (!this.eventAdded) {
      document.body.addEventListener('touchstart', this.touchStart);
      this.eventAdded = true;
    }
  }

  setIframes(iframes) {
    this.iframes = iframes;
  }

  // HACK ALERT:
  // DEV-10772
  // This is to fix the iOS safari issue where iframes each hold their own focus. This prevents the normal focus
  // event from firing for the iframe when another element gains focus, so we have to manually fire a new focusin
  // event so it will bubble up and be caught by our event handler on the body of the document
  static fireTouchStartEvent(elementId) {
    const newBlurEvent = document.createEvent('CustomEvent');
    newBlurEvent.initCustomEvent('touchstart', true, true, false);
    document.getElementById(`CollectJSInline${elementId}`).dispatchEvent(newBlurEvent);
  }

  static canElementReceiveFocus(element) {
    return (
      element.matches("a[href]:not([tabindex='-1'])") ||
      element.matches("area[href]:not([tabindex='-1'])") ||
      element.matches("input:not([disabled]):not([tabindex='-1'])") ||
      element.matches("select:not([disabled]):not([tabindex='-1'])") ||
      element.matches("textarea:not([disabled]):not([tabindex='-1'])") ||
      element.matches("button:not([disabled]):not([tabindex='-1'])") ||
      element.matches("iframe:not([tabindex='-1'])") ||
      element.matches("[tabindex]:not([tabindex='-1'])") ||
      element.matches("[contentEditable=true]:not([tabindex='-1'])")
    );
  }
}
