class TokenResponse {
  /**
   * @param type
   * @param token
   * @param initiatedBy
   * @param tokenData
   * @param wallet
   */
  constructor(type, token, initiatedBy, tokenData, wallet) {
    this.tokenType = type;
    this.token = token;
    this.initiatedBy = initiatedBy;
    const { card, check } = tokenData.data;
    this.card = card;
    this.check = check;
    this.wallet = wallet;
  }

  static createEmptyWalletData() {
    return {
      cardDetails: null,
      cardNetwork: null,
      email: null,
      billingInfo: {
        address1: null,
        address2: null,
        firstName: null,
        lastName: null,
        postalCode: null,
        city: null,
        state: null,
        country: null,
        phone: null,
      },
      shippingInfo: {
        method: null,
        address1: null,
        address2: null,
        firstName: null,
        lastName: null,
        postalCode: null,
        city: null,
        state: null,
        country: null,
        phone: null,
      },
    };
  }
}

export default TokenResponse;
