import OuterGooglePayField from './GooglePay/OuterGooglePayField';
import { ApplePayField } from './ApplePay';

class TokenService {
  static async updateToken(token, tokenizationKey, fields, timeoutDuration, cartCorrelationId) {
    let timeout;

    if (timeoutDuration > 0) {
      timeout = window.setTimeout(() => {
        throw new Error(
          'Collect.js internal error - failed to update token. No response one or more fields.',
        );
      }, timeoutDuration);
    }

    let tokenPromises = fields.map(field => {
      if (field instanceof OuterGooglePayField) {
        return field.updateToken();
      }

      if (field instanceof ApplePayField) {
        return field.updateToken(token, tokenizationKey, cartCorrelationId);
      }

      return null;
    });

    tokenPromises = tokenPromises.filter(promiseOrNull => promiseOrNull instanceof Promise);

    const results = await Promise.all(tokenPromises);
    window.clearTimeout(timeout);

    const failures = results.filter(result => result.result !== 'success');

    if (failures.length > 0) {
      // eslint-disable-next-line no-console
      console.error(failures);
      throw new Error('Failed to update token.');
    }

    return token;
  }
}

export default TokenService;
