import { OuterGooglePayField, OuterGooglePayFieldFactory } from '../GooglePay';
import { ApplePayField, ApplePayFieldFactory } from '../ApplePay';

class FieldFactory {
  constructor(config) {
    this.config = config;
    this.googlePayFieldFactory = new OuterGooglePayFieldFactory(this.config);
    this.applePayFieldFactory = new ApplePayFieldFactory(this.config);
  }

  create(type, options) {
    switch (type) {
      case OuterGooglePayField.FIELD_TYPE:
        return this.googlePayFieldFactory.create(options);
      case ApplePayField.FIELD_TYPE:
        return this.applePayFieldFactory.create(options);
      default:
        throw new Error(`Collect.js internal error - unknown field type ${type}`);
    }
  }
}

export default FieldFactory;
