import Eventable from '../Eventable';
import GooglePayHtml from './OuterGooglePay.html';
import OuterGooglePayDispatcher from './OuterGooglePayDispatcher';
import GooglePayCSS from './OuterGooglePay.css';
import URLParser from '../URLParser';

let completeCallbacksToUnmount = [];
let updatedTokenCallbacksToUnmount = [];

class OuterGooglePayField extends Eventable {
  constructor(options) {
    super();
    this.country = options.country;
    this.price = options.price;
    this.currency = options.currency;
    this.billingAddressRequired = options.billingAddressRequired;
    this.billingAddressParameters = options.billingAddressParameters;
    this.shippingAddressRequired = options.shippingAddressRequired;
    this.shippingAddressParameters = options.shippingAddressParameters;
    this.buttonType = options.buttonType;
    this.buttonColor = options.buttonColor;
    this.buttonLocale = options.buttonLocale;
    this.totalPriceStatus = options.totalPriceStatus;
    this.emailRequired = options.emailRequired;
    this.merchantId = options.merchantId;
    this.merchantName = options.merchantName;
    this.cardBrands = options.cardBrands;
    this.environment = options.environment;
    this.token = options.token;
    this.tokenizationKey = options.tokenizationKey;
    this.cartCorrelationId = options.cartCorrelationId;
    this.listener = options.listener;

    this.iframe = null;
  }

  // eslint-disable-next-line class-methods-use-this
  unmount(selector) {
    document.querySelectorAll(selector).forEach(container => {
      while (container.firstChild) {
        container.removeChild(container.firstChild);
      }
    });

    this.unmountListeners();
  }

  getUrl() {
    const gwroot = URLParser.googlePayIFrameRootUrl;

    const urlParameters = new URLSearchParams();

    urlParameters.set('country', this.country);
    urlParameters.set('price', this.price);
    urlParameters.set('currency', this.currency);
    urlParameters.set('billingAddressRequired', this.billingAddressRequired);
    urlParameters.set('billingAddressParameters', JSON.stringify(this.billingAddressParameters));
    urlParameters.set('shippingAddressRequired', this.shippingAddressRequired);
    urlParameters.set('shippingAddressParameters', JSON.stringify(this.shippingAddressParameters));
    urlParameters.set('buttonType', this.buttonType);
    urlParameters.set('buttonColor', this.buttonColor);
    urlParameters.set('buttonLocale', this.buttonLocale);
    urlParameters.set('totalPriceStatus', this.totalPriceStatus);
    urlParameters.set('emailRequired', this.emailRequired);
    urlParameters.set('merchantId', this.merchantId);
    urlParameters.set('merchantName', this.merchantName);
    urlParameters.set('cardBrands', JSON.stringify(this.cardBrands));
    urlParameters.set('environment', this.environment);
    urlParameters.set('token', this.token);
    urlParameters.set('tokenizationKey', this.tokenizationKey);
    urlParameters.set('cartCorrelationId', this.cartCorrelationId);

    return `${gwroot}/token/google_pay_field.php?${urlParameters.toString()}`;
  }

  mount(selector) {
    document.querySelectorAll(selector).forEach(container => {
      const parser = new window.DOMParser();
      const html = parser.parseFromString(GooglePayHtml, 'text/html');
      const iframe = html.querySelector('iframe');
      iframe.setAttribute('src', this.getUrl());

      const styles = document.createElement('style');
      styles.type = 'text/css';
      styles.innerText = GooglePayCSS;

      // eslint-disable-next-line
      if (typeof window.__webpack_nonce__ !== 'undefined') {
        // eslint-disable-next-line
        styles.setAttribute('nonce', window.__webpack_nonce__);
      }

      if (container instanceof window.Node) {
        container.appendChild(styles);
        container.appendChild(iframe);
        this.iframe = iframe;
      }
    });

    this.mountListeners();
  }

  unmountListeners() {
    completeCallbacksToUnmount.forEach(callback => {
      this.listener.removeListener('complete', callback);
    });
    completeCallbacksToUnmount = [];

    updatedTokenCallbacksToUnmount.forEach(callback => {
      this.listener.removeListener('updatedToken', callback);
    });
    updatedTokenCallbacksToUnmount = [];
  }

  mountListeners() {
    const onComplete = data => {
      this.invokeCallbacks('complete', data);
    };
    this.listener.on('complete', onComplete);
    completeCallbacksToUnmount.push(onComplete);

    const onUpdatedToken = data => {
      this.invokeCallbacks('updatedToken', data);
    };
    this.listener.on('updatedToken', onUpdatedToken);
    updatedTokenCallbacksToUnmount.push(onUpdatedToken);
  }

  updateToken() {
    return new Promise(resolve => {
      const updateToken = () => {
        resolve({
          result: 'success',
        });
        this.removeListener('updatedToken', updateToken);
      };

      this.on('updatedToken', updateToken);
      OuterGooglePayDispatcher.dispatchUpdateToken(this);
    });
  }

  static getAddress2(address2, address3) {
    const address = `${address2 || ''} ${address3 || ''}`.trim();

    if (address.length === 0) {
      return null;
    }

    return address;
  }

  static getFirstName(name) {
    if (typeof name !== 'string') {
      return null;
    }

    const namePieces = name.split(' ');
    return namePieces.shift();
  }

  static getLastName(name) {
    if (typeof name !== 'string') {
      return null;
    }

    const namePieces = name.split(' ');
    namePieces.shift();
    return namePieces.join(' ');
  }

  static getPhoneNumber(phone) {
    if (typeof phone !== 'string') {
      return null;
    }

    // https://stackoverflow.com/a/1862219
    return phone.replace(/\D/g, '');
  }

  /**
   * Example paymentInfo
   *
   * {
   *  "apiVersionMinor": 0,
   *  "apiVersion": 2,
   *  "paymentMethodData": {
   *    "description": "Visa••••1234",
   *    "tokenizationData": {
   *      "type": "PAYMENT_GATEWAY",
   *      "token": "..."
   *    },
   *    "type": "CARD",
   *    "info": {
   *      "cardNetwork": "VISA",
   *      "cardDetails": "1234",
   *      "billingAddress": {
   *        "address3": "",
   *        "sortingCode": "",
   *        "address2": "APT 2L",
   *        "countryCode": "US",
   *        "address1": "123 Fake Street",
   *        "postalCode": "12345",
   *        "name": "John Doe",
   *        "locality": "New York",
   *        "administrativeArea": "NY"
   *      }
   *    }
   *  },
   *  "shippingAddress": {
   *    "phoneNumber": "+1 800-123-4567",
   *    "address3": "",
   *    "sortingCode": "",
   *    "address2": "APT 2L",
   *    "countryCode": "US",
   *    "address1": "123 Fake Street",
   *    "postalCode": "12345",
   *    "name": "John Doe",
   *    "locality": "New York",
   *    "administrativeArea": "NY"
   *  },
   *  "email": "test@example.com"
   * }
   */
  static getWalletDataFromPaymentData(paymentInfo) {
    const shippingAddress = paymentInfo?.paymentData?.paymentResponse?.shippingAddress;
    const cardInfo = paymentInfo?.paymentData?.paymentResponse?.paymentMethodData?.info;
    const billingAddress = cardInfo?.billingAddress;
    const email = paymentInfo?.paymentData?.paymentResponse?.email;

    return {
      cardDetails: cardInfo?.cardDetails,
      cardNetwork: cardInfo?.cardNetwork.toLowerCase(),
      email: email || null,
      billingInfo: {
        address1: billingAddress?.address1 || null,
        address2: OuterGooglePayField.getAddress2(
          billingAddress?.address2,
          billingAddress?.address3,
        ),
        firstName: OuterGooglePayField.getFirstName(billingAddress?.name) || null,
        lastName: OuterGooglePayField.getLastName(billingAddress?.name) || null,
        postalCode: billingAddress?.postalCode || null,
        city: billingAddress?.locality || null,
        state: billingAddress?.administrativeArea || null,
        country: billingAddress?.countryCode || null,
        phone: OuterGooglePayField.getPhoneNumber(billingAddress?.phoneNumber) || null,
      },
      shippingInfo: {
        address1: shippingAddress?.address1 || null,
        address2: OuterGooglePayField.getAddress2(
          shippingAddress?.address2,
          shippingAddress?.address3,
        ),
        firstName: OuterGooglePayField.getFirstName(shippingAddress?.name) || null,
        lastName: OuterGooglePayField.getLastName(shippingAddress?.name) || null,
        postalCode: shippingAddress?.postalCode || null,
        city: shippingAddress?.locality || null,
        state: shippingAddress?.administrativeArea || null,
        country: shippingAddress?.countryCode || null,
        phone: OuterGooglePayField.getPhoneNumber(shippingAddress?.phoneNumber) || null,
      },
    };
  }
}

OuterGooglePayField.FIELD_TYPE = 'googlePay';

export default OuterGooglePayField;
