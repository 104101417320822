class OuterGooglePayDispatcher {
  static dispatchUpdateToken(googlePayField) {
    googlePayField.iframe.contentWindow.postMessage(
      {
        action: 'google-pay-update-token',
      },
      '*',
    );
  }
}

export default OuterGooglePayDispatcher;
