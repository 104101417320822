import axios from 'axios';
import UrlParser from './URLParser';

class TokenRepository {
  static fetchToken(params, count = 0) {
    return axios
      .post(UrlParser.tokenUrl, params)
      .then(response => {
        // Reset count
        return response.data;
      })
      .catch(error => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors[0] &&
          error.response.data.errors[0].message
        ) {
          // eslint-disable-next-line no-console
          console.debug(`${error.response.data.errors[0].message}\nRetrying...`);
        } else {
          // eslint-disable-next-line no-console
          console.debug(error);
        }
        // Retry up to 10 times
        if (count <= 9) {
          return TokenRepository.fetchToken(params, count + 1);
        }

        throw new Error('Giving up after 10 tries');
      });
  }

  static async lookupToken(token, tokenizationKey, cartCorrelationId) {
    try {
      return axios.post(
        UrlParser.lookupUrl,
        {
          tokenId: token,
          tokenizationKey,
          cartCorrelationId,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.response.data.errors[0].message);
      throw e;
    }
  }

  static async updateMultipartToken(token, tokenizationKey, fields, cartCorrelationId) {
    try {
      const requestPayload = {
        tokenId: token,
        tokenizationKey,
        cartCorrelationId,
        data: fields,
      };

      return axios.post(UrlParser.saveMultipartTokenUrl, requestPayload);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.response.data.errors[0].message);
      throw e;
    }
  }
}

export default TokenRepository;
