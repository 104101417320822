class Eventable {
  constructor() {
    this.callbacks = [];
  }

  on(eventName, func) {
    if (!(func instanceof Function)) {
      // eslint-disable-next-line no-console
      console.error('second argument to `on` must be a function');
      return null;
    }

    if (!(this.callbacks[eventName] instanceof Array)) {
      this.callbacks[eventName] = [];
    }

    this.callbacks[eventName].push(func);

    return undefined;
  }

  removeListener(eventName, func) {
    if (!(this.callbacks[eventName] instanceof Array)) {
      return undefined;
    }

    const index = this.callbacks[eventName].findIndex(callback => callback === func);
    this.callbacks[eventName].splice(index, 1);

    return undefined;
  }

  invokeCallbacks(eventName, payload) {
    if (!(this.callbacks[eventName] instanceof Array)) {
      return;
    }

    this.callbacks[eventName].forEach(func => func(payload));
  }
}

export default Eventable;
