/**
 * This exists to prevent an issue in phantomjs browsers where the corejs polyfill fails to provide Object.fromEntries.
 * The polyfill correctly works for IE11, but mysteriously fails for phantomjs, causing CI failures.
 * At some point we should move away from phantomjs and we can then remove this hack.
 * @param entries
 * @returns {{}|*}
 * @constructor
 */
export function ObjectFromEntries(entries) {
  if (typeof Object.fromEntries === 'function') {
    return Object.fromEntries(entries);
  }

  const object = {};
  for (let i = 0; i < entries.length; i += 1) {
    // eslint-disable-next-line prefer-destructuring
    object[entries[i][0]] = entries[i][1];
  }

  return object;
}

export function isDevelopment() {
  const url = new URL(window.location);

  return ['dev0-sch.corp.nmi.com', 'dev1-sch.corp.nmi.com', 'transdev.nmi.com'].includes(
    url.hostname,
  );
}
